import React from 'react'
import SideNav from '../../components/student/Sidenav'
import StudentDashboard from '../../components/student/Dashboard'
import Header from '../../components/Header'
import Profile from '../../components/student/Profile'
function AOCProfile() {
  return (
  <>
        <Header/>
        <SideNav/>
        <Profile/>
    </>
  )
}

export default AOCProfile

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Config from '../../config/config'
import Img from '../../images/bg.jpg'
import { FaUsers, FaFileAlt, FaCalendarAlt, FaLaptopHouse, FaUsersCog, FaCheckSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Dashboard() {
  const [applicationStatus, setApplicationStatus] = useState('');
  const config = Config();
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;
  
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getApplicationStatus();
  }, []);

  const getApplicationStatus = () => {
    const url = `${config.base_url}student/application/status/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setApplicationStatus(res.data.message);
        } else if (res.status === 401) {
          console.log('Failed to get status');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log('Failed to get status');
        }
      });
  };

  const approveApplication = () => {
    const url = `${config.base_url}student/application/approve/`;
    axios
      .post(url, {}, headers)
      .then((res) => {
        if (res.status === 200) {
          alert('Application approved!');
          setApplicationStatus('Approved');
        }
      })
      .catch((error) => {
        console.log('Failed to approve application', error);
      });
  };

  const features = [
    {
      icon: <FaUsers />,
      text: 'Designed to provide a comprehensive and innovative learning experience.',
    },
    {
      icon: <FaFileAlt />,
      text: 'Keen focus on accessibility and flexibility. The programme does not distinguish between part-time or full-time.',
    },
    {
      icon: <FaCalendarAlt />,
      text: 'All our classes are delivered after working hours, on weekends or on public holidays.',
    },
    {
      icon: <FaLaptopHouse />,
      text: 'Offered through a blended learning model, merging the benefits of physical and online contact sessions.',
    },
    {
      icon: <FaUsersCog />,
      text: 'Provides a dynamic and engaging learning environment that accommodates the diverse schedules of our students.',
    },
    {
      icon: <FaCheckSquare />,
      text: 'Consistent assessments leading up to the final examinations ensure that students are continuously engaged.',
    },
  ];

  return (
    <>
      <section className="dash-img">
        <div className="card mb-3">
          <img src={Img} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title">About AOC</h5>
            <p className="card-text text-align-center">
              Accounts On Call Institute (AOC), established in July 2021, stands as a premier institution fostering future influential Chartered Accountants, for a global economy. Our commitment goes beyond traditional education; we aim to create a supportive and inclusive learning environment. At the core of our brand philosophy, is the powerful phrase “experience the difference!" - an invitation to embark on a transformative educational journey!
            </p>
            <p>
              AOC is registered with the Ministry of Higher and Tertiary Education, Innovation Science, and Technology Development as a non-Governmental technical and vocational education training institution. Our Certificate in Theory of Accounting (CTA) is accredited by the Institute of Chartered Accountants of Zimbabwe (ICAZ).
            </p>

            <h5 className="card-title">Our CTA Programme</h5>
            <div className="row">
              {features.map((feature, index) => (
                <div className="col-md-6 mb-3" key={index}>
                  <div className="d-flex align-items-start">
                    <div className="text-success me-2" style={{ fontSize: '24px' }}>
                      {feature.icon}
                    </div>
                    <p className="card-text mb-0">{feature.text}</p>
                  </div>
                </div>
              ))}
            </div>

            <h5 className="card-title">
              APPLICATION STATUS: <b>{applicationStatus}</b>
            </h5>

            {applicationStatus === 'Pending' && (
              <>
                <p className="">
                  Your application is currently pending. An application fee of <b>$10</b> is required for processing.
                  Please submit the payment to continue. You can find our banking details <Link to="/banking-details">here</Link>.
                </p>
               
              </>
            )}

            {applicationStatus === 'Approved' && (
              <p className="text-success">
                Your application has been approved! 
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;

import React, { useEffect, useState } from 'react';
// import './style.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import { Spin } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header';
import LoginSideNav from '../LoginSidenav';
import setLocalStorage from '../../utils/localStorage';
// import Config from '../config/config';


function ITCRegistration() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [selectedITCAttempt, setSelectedITCAttempt] = useState('');
  const [selectedRelevantApplications, setSelectedRelevantApplication] = useState('');
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState('');
  const [loading,setLoading]= useState(false)
//   const config = Config();
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;


  const [formData, setFormData] = useState({
   
    degree_name: '',
    university_name: '',
    other_university_name: '',
    degree_class: '',
    year_attained: '',
    cta_attempt: '',
    cta_support: '',
    repeating_itc: '',
    itc_attempts: '',
    latest_results: null,
    last_itc_attempt: '',
    employment_status: '',
    employer_name: '',
    training_officer_name: '',
    training_officer_contact: '',
    cta_funding: '',
  });

  
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && files[0]) {
      const file = files[0];
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit

      if (file.size > maxSizeInBytes) {
        toast.error("File size exceeds the maximum limit of 5MB.");
        e.target.value = ""; 
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });

    if (name === 'university_name') {
      setSelectedUniversity(value);
    } else if (name === 'employment_status') {
      setSelectedEmploymentStatus(value);
    }else if (name === 'repeating_itc') {
      setSelectedITCAttempt(value);
    }
};


const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);

  // Check for file size limit (5MB)
  const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit
  const filesExceedLimit = Object.keys(formData).some((key) => {
      const value = formData[key];
      // Check if the value is a file and its size exceeds 5MB
      return value instanceof File && value.size > maxSizeInBytes;
  });

  if (filesExceedLimit) {
      setLoading(false);
      toast.error('One or more files exceed the 5MB size limit. Please upload smaller files.');
      return; // Prevent form submission if there are large files
  }

  const data = new FormData();
  Object.keys(formData).forEach((key) => {
      const value = formData[key];
      data.append(key, value === null ? '' : value);
  });

  try {
      const response = await axios.post(
          'https://webservice.accsoncall.com/api/student/application/itc/',
          data,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data',
              },
          }
      );

      console.log(response.data);
      setLoading(false);
      const existingUserInfo = JSON.parse(localStorage.getItem("userInfo"));
      setLocalStorage("userInfo", {
        ...existingUserInfo,
        is_registered: true,
        account_type: 'itc_registration',
      });
      navigate('/itc-studentDashboard');
      toast.success('Application submitted successfully! Please note you will be notified once your application has been approved');
  
  } catch (error) {
      setLoading(false);
      console.log('Error response:', error.response);
      const errorData = error.response?.data;
      const errorMessage = errorData?.error || "Application timeout, please refresh your page and try to submit again";

      toast.error(`Failed to submit application: ${errorMessage}`);
  }
};



  return (
    <>
    <Header/>
    <LoginSideNav/>
    <main>
      <div class="container">
    <div className="reg-container">
      <ToastContainer />
      <Spin spinning={loading}>
      <header>IAC Registration</header>

      <form onSubmit={handleSubmit}>
        <div className="form first">
        <div className="details address">
            <div className="fields">
                <div className="input-field">
                  <label htmlFor="degree_name">Degree Name</label>
                  <input id="degree_name" name="degree_name" type="text" placeholder="Enter Degree Name" required onChange={handleInputChange} />
                </div>
                <div class="input-field">
                  <label>University Name</label>
                  <select name="university_name" onChange={handleInputChange} required>
                    <option disabled selected>Select Option</option>
                    <option value="Africa University">Africa University (AU)</option>
                    <option value="Arrupe Jesuit University">Arrupe Jesuit University (AJU)</option>
                    <option value="Bindura University of Science Education">Bindura University of Science Education</option>
                    <option value="Catholic University of Zimbabwe">Catholic University of Zimbabwe (CUZ)</option>
                    <option value="Chinhoyi University of Technology">Chinhoyi University of Technology (CUT)</option>
                    <option value="Great Zimbabwe University">Great Zimbabwe University (GZU)</option>
                    <option value="Harare Institute of Technology">Harare Institute of Technology (HIT)</option>
                    <option value="IIE MSA">IIE MSA (previously Monash South Africa)</option>
                    <option value="Lupane State University">Lupane State University (LSU)</option>
                    <option value="Midlands State University">Midlands State University (MSU)</option>
                    <option value="National University of Science and Technology">National University of Science and Technology (NUST)</option>
                    <option value="Nelson Mandela Metropolitan University">Nelson Mandela Metropolitan University (NMMU)</option>
                    <option value="Rhodes University">Rhodes University (RU)</option>
                    <option value="Solusi University">Solusi University (Solusi)</option>
                    <option value="University of Cape Town">University of Cape Town (UCT)</option>
                    <option value="University of Johannesburg">University of Johannesburg (UJ)</option>
                    <option value="University of South Africa">University of South Africa (UNISA)</option>
                    <option value="University of Witwatersrand">University of Witwatersrand (Wits)</option>
                    <option value="University of Zimbabwe">University of Zimbabwe (UZ)</option>
                    <option value="Women’s University in Africa">Women’s University in Africa (WUA)</option>
                    <option value="Zimbabwe Ezekiel Guti University">Zimbabwe Ezekiel Guti University (ZEGU)</option>
                    <option value="Zimbabwe Open University">Zimbabwe Open University (ZOU)</option>
                    <option value="Other">Other (please specify)</option>
                </select>

              </div>
              {selectedUniversity === 'Other' && (
                  <div className="input-field">
                    <label htmlFor="other_university">Other University Name</label>
                    <input id="other_university" name="other_university_name" type="text" placeholder="Enter other university name" onChange={handleInputChange} />
                  </div>
                )}
                <div className="input-field">
                  <label htmlFor="degree_class">Degree Class</label>
                  <select id="degree_class" name="degree_class" required onChange={handleInputChange}>
                    <option value="" disabled selected>Select Degree Class</option>
                    <option value="1">1</option>
                    <option value="2.1">2.1</option>
                    <option value="2.2">2.2</option>
                    <option value="3">3</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="input-field">
                  <label htmlFor="year_attained">Year Degree Attained</label>
                  <input id="year_attained" type="date" name="year_attained" required onChange={handleInputChange} />
                </div>
                <div className="input-field">
                  <label htmlFor="year_attained">CTA Institution</label>
                  <input id="cta_institution" type="text" name="cta_institution" required onChange={handleInputChange} />
                </div>
                <div className="input-field">
                  <label htmlFor="year_attained">Year CTA Completed</label>
                  <input id="year_cta_completed" type="date" name="year_cta_completed" required onChange={handleInputChange} />
                </div>

              <div className="input-field">
                <label htmlFor="cta_attempt">Did You Attend AOC CTA Support</label>
                <select id="cta_support" name="cta_support" required onChange={handleInputChange}>
                    <option value="" disabled selected>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>

              </div>  
              <div className="input-field">
                  <label htmlFor="degree_class">Are you repeating IAC</label>
                  <select id="repeating_itc" name="repeating_itc" required onChange={handleInputChange}>
                    <option value="" disabled selected>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>  
                <div className="input-field">
                    <label htmlFor="cta_attempt">Number Of Attempts</label>
                    <input id="itc_attempts" type="number" name="itc_attempts" required onChange={handleInputChange} disabled={selectedITCAttempt === 'No'} />

                </div> 
                <div className="input-field">
                  <label htmlFor="degree_class">Is this your last Attempt?</label>
                  <select id="last_itc_attempt" name="last_itc_attempt" required onChange={handleInputChange} disabled={selectedITCAttempt === 'No'}>
                    <option value="" disabled selected>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              <div className="input-field">
                <label htmlFor="latest_attempt_results">Upload Latest Results</label>
                <input id="latest_results" name="latest_results" type="file" onChange={handleInputChange} disabled={selectedITCAttempt === 'No'} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
              </div>
              <div class="input-field">
                  <label>Are you currently employed</label>
                  <select name="employment_status" onChange={handleInputChange} required>
                      <option disabled selected>Select option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                  </select>
              </div>
            
              <div className="input-field">
                <label>Employer Name</label>
                <input name="employer_name" type="text" onChange={handleInputChange} placeholder="Enter employer name" disabled={selectedEmploymentStatus==='no'} />
              </div>
              <div className="input-field">
                <label>Training Officer Contact Name</label>
                <input name="training_officer_name" type="text" onChange={handleInputChange} placeholder="employer / training officer contact name"  disabled={selectedEmploymentStatus==='no'} />
              </div>

              <div className="input-field">
                <label>Training Officer Phone Number</label>
                <input name="training_officer_contact" type="text"onChange={handleInputChange}  placeholder="employer / training officer phone"  disabled={selectedEmploymentStatus==='no'}  />
              </div>
            </div>
           <div>

            <div className="buttons">
              <button type="submit" className="submit">
                <span className="btnText">Register</span>
                <i className="uil uil-navigator"></i>
              </button>
            </div>
          </div>
          </div>
        </div>
      </form>
      </Spin>
      
    </div>
    </div>
    </main>
    </>
  );
}

export default ITCRegistration;
import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaCheck, FaDownload, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import {MdCancel} from 'react-icons/md';
import { CSVLink } from 'react-csv'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function JopApplicants() {
  const [applicants, setApplicants]= useState([])
  const [applicantsLoading, SetApplicantsLoading] = useState(false)
  const [applicantsDetailsModal, SetApplicantsDetailsModal] = useState(false)
  const [vacanciesLoading, setVacanciesLoading]=useState(false)
  const [vacancies, setVacancies]=useState([])
  const [rowData, setRowData] = useState({})
  const [applicantsDetailsForm] = Form.useForm()
  
  const config = Config()
  useEffect(() => {
    getJobApplicants()
    getVacancies()
  }, [])
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };
  const triggerToast = () => {
    toast("Application Approved successfully");
  }
  const triggerError = () => {
    toast("Failed to approve applicant");
  }
  const getJobApplicants = () => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}application/job_application/`;
    axios
      .get(url, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          setApplicants(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const getFilteredJobApplicants = (values) => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}application/filtered_job_application/`;
    let payload = {
      start_date: values.start_date,
      end_date: values.end_date,
      status: values.status,
      vacancy: values.vacancy,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          setApplicants(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const getVacancies = () => {
    setVacanciesLoading(true)
    const url = ` ${config.base_url}vacancies/view_all_vacancies/`;
    axios
      .get(url, headers)
      .then((res) => {
        setVacanciesLoading(false)
        if (res.status === 200) {
          setVacancies(res.data.Vacancies);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setVacanciesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const ApproveApplicants = (values) => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}approve/student/cta/`;
    const payload = {
      id: values.id,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          triggerToast()
          window.location.href = '/cta_applicants';
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        triggerError()
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };

  const rejectApplicants = (rowData) => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}reject/student/cta/`;
    const payload = {
      id: rowData.id,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          triggerToast()
          window.location.href = '/cta_applicants';
        } else if (res.status === 401) {
          window.location.href = '/cta_applicants';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };
  
  const applicantsData = (applicants.Applicants ?? []).map((item) => {
    return {
      ...item,
    };
  });
  const applicantsHeaders = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Address ', key: 'address'},
    { label: 'Registration Number ', key: 'reg_number'},
    { label: 'Whatsapp Number ', key: 'whatsapp_number'},
  
  ]

  return (
    <>
      <ToastContainer />
    <div className="pagetitle">
      <h1>Job Applicants</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Applicants</li>
          <li className="breadcrumb-item active">Job Applicants</li>
        </ol>
       
      </nav>
    </div>
    <section className="section">
      <div className="">
      <div className="card border-0 p-0 rounded-0">
          <Form
              // form={form}
              name="basic"
              onFinish={(values) => getFilteredJobApplicants(values)}
              initialValues={{ mode: 'all' }}
            >
              <div className="row px-4 m-0">
                <div className="col-12 col-md-3 mt-4">
                  <label className="text-green-2 fw-700 px-0">Status</label>
                  <Form.Item name="status">
                    <select className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0">
                      <option value="">Select Status</option>
                      <option value="pending"> Pending Applications</option>
                      <option value="approved"> Approved Applications </option>
                      <option value="rejected"> Rejected Applications </option>
                    </select>
                  </Form.Item>
                </div>
                <div className="col-12 col-md-3 mt-4">
                  <label className="text-green-2 fw-700 px-0">Vacancy</label>
                  <Form.Item name="vacancy">
                    <select className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0">
                      <option value="">Select Vacancy</option>
                      {vacancies?.map((vacancyName) => {
                        return (
                          <option value={vacancyName.id}>
                            {vacancyName.title}
                          </option>
                        );
                      })}
                    </select>
                  </Form.Item>
                </div>
                <div className="col-12 col-md-3 col-lg-3 mt-4">
                  <label className="text-green-2 fw-700 px-0">Start Date</label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please select start day!',
                      },
                    ]}
                    name="start_date"
                  >
                    <input
                      className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                      type="date"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-3 col-lg-3 mt-4">
                  <label className="text-green-2 fw-700 px-0">End Date</label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please select end day!',
                      },
                    ]}
                    name="end_date"
                  >
                    <input
                      className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                      type="date"
                    />
                  </Form.Item>
                </div>
                <div className="col-2 col-md-2 col-lg-2 mt-4" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <input
                    className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                    type="submit"
                    value="Filter"
                  />    
                  <CSVLink
                    className="ms-3 btn btn-circle-lg green-gradient-bg-1  text-white fw-800 border-0 rounded-3 box-shadow-btn"
                    data={applicantsData}
                    headers={applicantsHeaders}
                    filename={'CTA Applicants.csv'}
                  >
                    Download 
                </CSVLink>
                </div>
                
              </div><br/>
            </Form>
          </div>
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    Applicants
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
              
                <div className="table-responsive">
                <Spin spinning={applicantsLoading}>
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'Title', field: 'title' },
                    { title: 'First Name', field: 'first_name' },
                    { title: 'Last Name', field: 'last_name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Phone Number', field: 'phone_number' },
                    { title: 'Current Salary ', field: 'current_salary'},
                    { title: 'Expected Salary', field: 'expected_salary' },
                    { title: 'Currency', field: 'currency' },
                    { title: 'Position Applied', field: 'vacancy_name' },   
                    { title: 'Status', field: 'status' },                    
                    {
                      title: 'Date Applied',
                      field: 'date_created',
                      render: rowData => {
                        const date = new Date(rowData.date_created);
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`;
                      }
                    },
                    {
                      title: 'CV',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.cv}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                    {
                      title: 'Additional Documents',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.additional_docs}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                         <FaEye />
                        </a>
                      ),
                    },
                    
                  ]}
                  actions={[
                    {
                      icon: () => <FaCheck />,
                      tooltip: 'Approve Application',
                      onClick: (event, rowData) => {
                        setRowData(rowData)
                        // applicantsDetailsForm.setFieldsValue({
                        //   ...rowData,
                        // })
                        // SetApplicantsDetailsModal(true)
                      },
                    },
                    {
                      icon: () => <MdCancel />,
                      tooltip: 'Reject Application',
                      onClick: (event, rowData) => {
                       rejectApplicants(rowData)
                      },
                    },
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={applicantsData}
                />
                </Spin>
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}

export default JopApplicants

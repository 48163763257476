import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaCheck, FaDownload, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import {MdCancel} from 'react-icons/md';
import { CSVLink } from 'react-csv'
function ITCStudents() {
  const [applicants, setApplicants]= useState([])
  const [applicantsLoading, setStudentsLoading] = useState(false)
  const [applicantsDetailsModal, SetApplicantsDetailsModal] = useState(false)
  const [rowData, setRowData] = useState({})
  const [applicantsDetailsForm] = Form.useForm()
  const config = Config()
  useEffect(() => {
    getITCStudents()
  }, [])

  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const getITCStudents = () => {
    setStudentsLoading(true)
    const url = ` ${config.base_url}student/registered_students/itc/`;
    axios
      .get(url, headers)
      .then((res) => {
        setStudentsLoading(false)
        if (res.status === 200) {
          setApplicants(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setStudentsLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const ApproveApplicants = (values) => {
    setStudentsLoading(true)
    const url = ` ${config.base_url}approve/student/itc/`;
    const payload = {
      id: values.id,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        setStudentsLoading(false)
        if (res.status === 200) {
          // Notifications('success', 'Success', 'Student Approved Successfully')
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setStudentsLoading(false)
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };
  
  const applicantsData = (applicants.Applicants ?? []).map((item) => {
    return {
      ...item,
    };
  });
  const applicantsHeaders = [
    { label: 'Title', key: 'title' },
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Gender', key: 'gender' },
    { label: 'Whatsapp Number ', key: 'whatsapp_number'},
    { label: 'ID Number ', key: 'id_number'},
    { label: 'Degree Name', key: 'degree_name'},
    { label: 'University Name', key: 'university_name'},
    { label: 'CTA Support', key: 'cta_support'},
    { label: 'Repeating IAC', key: 'repeating_itc'},
    { label: 'Last IAC Attempt', key: 'last_itc_attempt'},
    { label: 'IAC Attempts', key: 'itc_attempts'},
    { label: 'Year CTA Completed', key: 'year_cta_completed'},
    { label: 'Employment Status', key: 'employment_status'},
    { label: 'Employer Name', key: 'employer_name'},
    { label: 'Training Officer Name', key: 'training_officer_name'},
    { label: 'Training Officer Contact', key: 'training_officer_contact'},
    { label: 'Registration Number ', key: 'reg_number'},

  
  ]

  return (
    <>
      <Modal
        style={{ marginTop: '-20px' }}
        visible={applicantsDetailsModal}
        footer={false}
        closable={true}
        onCancel={() => {
            SetApplicantsDetailsModal(false)
        }}
        width={800}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">IAC Student Details</h5>
                 
                  <Spin spinning={applicantsLoading}>
                    <Form
                      form={applicantsDetailsForm}
                      name="editOrderForm"
                      autoComplete="off"
                    >
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">First Name</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'first_name'
                          rules={[
                            {
                              required: true,
                              message: 'Enter student name'
                            }
                          ]
                          }>
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Last Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'last_name'
                          rules={[
                            {
                              required: true,
                              message: 'Enter student Last name'
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Phone Number</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'phone_number'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'email'
                          rules={[
                            {
                              required: true,
                            
                            }
                          ]
                          }>
                        <input type="email" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Gender</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'gender'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">ID Number</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'id_number'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Date Of Birth</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'date_of_birth'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Address</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'address'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Next of Kin Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'next_of_kin_name'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Next Of Kin Email</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'next_of_kin_email'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Next of Kin Address</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'next_of_kin_address'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Programme</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'programme'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Degree Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'degree_name'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Degree Class</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'degree_class'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Year Attained</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'year_attained'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">CTA Attempt</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'cta_attempt'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Average Mark attempted Elsewhere</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'average_mark_attempted_elsewhere'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Year of Previous Attempt</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'year_of_previous_attempt'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Is Employed</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'employment_status'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Employer Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'employer_name'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">CTA Funder</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'cta_funding'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Registration Number</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'reg_number'
                          rules={[
                            {
                              required: true,
                            }
                          ]
                          }>
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Approve Applicant</button>
                    </div>
                  </Form>
                  </Spin>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal> 
    <div className="pagetitle">
      <h1>IAC Registered Students</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Students</li>
          <li className="breadcrumb-item active">IAC Registered Students</li>
        </ol>
       
      </nav>
    </div>
    <section className="section">
      <div className="">
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    Students
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
              <CSVLink
              className="ms-3 btn btn-circle-lg green-gradient-bg-1  text-white fw-800 border-0 rounded-3 box-shadow-btn"
              data={applicantsData}
              headers={applicantsHeaders}
              filename={'IAC Students.csv'}
            >
              Download <FaDownload />
          </CSVLink>
                <div className="table-responsive">
                <Spin spinning={applicantsLoading}>
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'First Name', field: 'first_name' },
                    { title: 'Last Name', field: 'last_name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Phone Number', field: 'phone_number' },
                    { title: 'Whatsapp Number', field: 'whatsapp_number' },
                    { title: 'Registration Number', field: 'reg_number' },  
                    { title: 'ID Number ', field: 'id_number'},
                    { title: 'Degree Name', field: 'degree_name'},
                    { title: 'University Name', field: 'university_name'},
                    { title: 'CTA Support', field: 'cta_support'},
                    { title: 'Repeating IAC', field: 'repeating_itc'},
                    { title: 'Last IAC Attempt', field: 'last_itc_attempt'},
                    { title: 'IAC Attempts', field: 'itc_attempts'},
                    { title: 'Year CTA Completed', field: 'year_cta_completed'},
                    { title: 'Employment Status', field: 'employment_status'},
                    { title: 'Employer Name', field: 'employer_name'},
                    { title: 'Training Officer Name', field: 'training_officer_name'},
                    { title: 'Training Officer Contact', field: 'training_officer_contact'},
                    { title: 'Registration Number ', field: 'reg_number'},
                    
                    {
                      title: 'Date Joined',
                      field: 'date_created',
                      render: rowData => {
                        const date = new Date(rowData.date_created);
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`;
                      }
                    },
                    {
                      title: 'CTA Transcript',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.cta_transcript}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                    {
                      title: 'Degree Transcript',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.degree_transcript}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                    {
                      title: 'Degree Certificate',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.degree_certificate}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                    {
                      title: 'National ID',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.national_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                    {
                      title: 'Latest Results',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.latest_results}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                    
                    
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={applicantsData}
                />
                </Spin>
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}

export default ITCStudents

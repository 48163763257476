import React from 'react'
import SideNav from '../../../components/student/ITC/SideNav'
import Header from '../../../components/Header'
import Profile from '../../../components/student/Profile'
function ITCProfile() {
  return (
  <>
        <Header/>
        <SideNav/>
        <Profile/>
    </>
  )
}

export default ITCProfile

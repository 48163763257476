import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin, notification} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaCheck, FaDownload, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import {MdCancel} from 'react-icons/md';
import { CSVLink } from 'react-csv'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Quill } from 'react-quill';
import 'quill/dist/quill.bubble.css';

function JobVacancies() {
  const [applicants, setApplicants]= useState([])
  const [applicantsLoading, SetApplicantsLoading] = useState(false)
  const [applicantsDetailsModal, SetApplicantsDetailsModal] = useState(false)
  const [vacanciesLoading, setVacanciesLoading]=useState(false)
  const [addVacanciesModal, setAddVacanciesModal]=useState(false)
  const [vacancies, setVacancies]=useState([])
  const [rowData, setRowData] = useState({})
  const [vacancyDetailsForm] = Form.useForm()
  const [vacanciesModal, setVacanciesModal]=useState(false)
  const [description, setDescription]= useState('')
  const [api, contextHolder] = notification.useNotification();
  const [vacancyPreview, setVacancyPreview]=useState(false)
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  const cleanDescription = doc.body.textContent;

  const config = Config()

  useEffect(() => {
    getVacancies()
  }, [])

  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const triggerToast = () => {
    toast("Application Approved successfully");
  }
  const triggerError = () => {
    toast("Failed to approve applicant");
  }
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: 'Success',
      description:
        message,
    });
  };

  const getVacancies = () => {
    setVacanciesLoading(true)
    const url = ` ${config.base_url}vacancies/view_all_vacancies/`;
    axios
      .get(url, headers)
      .then((res) => {
        setVacanciesLoading(false)
        if (res.status === 200) {
          setVacancies(res.data.Vacancies);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setVacanciesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };

  const updateVacancies = (values) => {
    setVacanciesLoading(true)
    const url = ` ${config.base_url}vacancies/view_all_vacancies/${values.id}`;
    let payload = {
     title: values.title,
     description: values.description,
     summary: values.summary,
     deadline: values.deadline,
    }
    axios
      .patch(url, payload, headers)
      .then((res) => {
        setVacanciesLoading(false)
        if (res.status === 200) {
          window.location.href = '/job_vacancies';

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setVacanciesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const updateVacancyStatus = (values) => {
    setVacanciesLoading(true)
    const url = ` ${config.base_url}vacancies/view_all_vacancies/${values.id}`;
    axios
      .post(url, headers)
      .then((res) => {
        setVacanciesLoading(false)
        if (res.status === 200) {
          window.location.href = '/job_vacancies';

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setVacanciesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const addVacancies = (values) => {
    setVacanciesLoading(true)
    const url = ` ${config.base_url}vacancies/view_open_vacancies/`;
    let payload = {
     title: values.title,
     description: description,
     location: values.location,
     workplace_type: values.workplace_type,
     employment_type: values.employment_type,
     deadline: values.deadline,
    
    }
    console.log(description)
    axios
      .post(url, payload, headers)
      .then((res) => {
        setVacanciesLoading(false)
        if (res.status === 200) {
         let message= res.data.message
          openNotificationWithIcon('success', message)
          // window.location.href = '/job_vacancies';

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setVacanciesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const ApproveApplicants = (values) => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}approve/student/cta/`;
    const payload = {
      id: values.id,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          triggerToast()
          window.location.href = '/cta_applicants';
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        triggerError()
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };

  
  const vacanciesData = (vacancies ?? []).map((item) => {
    return {
      ...item,
    };
  });
  const applicantsHeaders = [
    { label: 'Title', key: 'title' },
    { label: 'Description', key: 'description' },
    { label: 'Summary', key: 'summary' },
    { label: 'Deadline', key: 'deadline' },
  ]

  Quill.register({
    'modules/font-size': Quill.import('attributors/style/size'),
    'modules/text-align': Quill.import('attributors/style/align'),  
  });
  
  return (
    <>
       {contextHolder}
      <Modal
        style={{ marginTop: '-20px' }}
        visible={vacanciesModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setVacanciesModal(false)
        }}
        width={800}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Edit Vacancy</h5>
                 
                  <Spin spinning={vacanciesLoading}>
                    <Form
                      form={vacancyDetailsForm}
                      onFinish={(values) => updateVacancies(values)}
                      name="editOrderForm"
                      autoComplete="off"
                    >
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Description</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'description'
                          >
                            <textarea type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Location</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'location'
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Workplace Type</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'workplace_type'
                          >
                           <select  className="form-control" id="inputEmail">
                              <option value="">Select Type</option>
                              <option value="Onsite">Onsite</option>
                              <option value="Hybrid">Hybrid</option>
                              <option value="Remote">Remote</option>
                          </select>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Employment Type</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'employment_type'
                          >
                           <select  className="form-control" id="inputEmail">
                              <option value="">Select Type</option>
                              <option value="Full-time">Full-time</option>
                              <option value="Part-time">Part-time</option>
                              <option value="Contract">Contract</option>
                              <option value="Volunteer">Volunteer</option>
                              <option value="Internship">Internship</option>
                          </select>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Deadline</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'deadline'
                          >
                        <input type="date" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Update</button>
                    </div>
                  </Form>
                  </Spin>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal> 
      <Modal
        style={{ marginTop: '-20px' }}
        visible={addVacanciesModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setAddVacanciesModal(false)
        }}
        width={900}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Add Vacancy</h5>
                 
                  <Spin spinning={vacanciesLoading}>
                    <Form
                      // form={vacancyDetailsForm}
                      onFinish={(values) => addVacancies(values)}
                      name="editOrderForm"
                      autoComplete="off"
                    >
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          rules={[
                            {
                              required: true,
                              message: 'Enter title'
                            }
                          ]
                          }
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Description</label>
                      <div class="col-sm-10">
                          <ReactQuill 
                            theme="snow"
                            value={description} onChange={setDescription}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                [{ align: [] }], // text align options
                                [{ font: [] }], // font family selector
                                [{ color: [] }],  
                              ],
                            }}
                          />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Location</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'location'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Location'
                            }
                          ]
                          }
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Workplace Type</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'workplace_type'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Description'
                            }
                          ]
                          }
                          >
                           <select  className="form-control" id="inputEmail">
                              <option value="">Select Type</option>
                              <option value="Onsite">Onsite</option>
                              <option value="Hybrid">Hybrid</option>
                              <option value="Remote">Remote</option>
                          </select>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Employment Type</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'employment_type'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Employment Type'
                            }
                          ]
                          }
                          >
                           <select  className="form-control" id="inputEmail">
                              <option value="">Select Type</option>
                              <option value="Full-time">Full-time</option>
                              <option value="Part-time">Part-time</option>
                              <option value="Contract">Contract</option>
                              <option value="Volunteer">Volunteer</option>
                              <option value="Internship">Internship</option>
                          </select>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Deadline</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'deadline'
                          rules={[
                            {
                              required: true,
                              message: 'Enter Deadline'
                            }
                          ]
                          }
                          >
                        <input type="date" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    
                    <div class="text-center">
                      <div class="row">
                        <div class="col-6">
                          <button type="submit" class="btn btn-primary">Add Vacancy</button> 
                        </div>
                        <div class="col-6">
                          <button type='button' onClick={setVacancyPreview} class="btn btn-secondary">Preview</button>
                        </div>
                      </div>
                    </div>
                  </Form>
                  </Spin>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal> 
      <Modal
        style={{ marginTop: '-20px' }}
        visible={vacancyPreview}
        footer={false}
        closable={true}
        onCancel={() => {
          setVacancyPreview(false)
        }}
        width={1200}
      >
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Vacancy Preview</h5>
                  <div 
                      dangerouslySetInnerHTML={{
                        __html: description 
                      }}
                  />
                   <div class="text-center">
                      <button type="button" onClick= {() => setVacancyPreview(false)} class="btn btn-primary">Close Preview</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    <div className="pagetitle">
      <h1>Job Vacancies</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Vacancies</li>
          <li className="breadcrumb-item active">Job Vacancies</li>
        </ol>
        <button type="submit" class="btn float-right blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"  onClick= {() => setAddVacanciesModal(true)}>
         Add Vacancy
        </button>
      </nav>
    </div>
    <section className="section">
      <div className="">
      
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    Vacancies
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
              
                <div className="table-responsive">
                <Spin spinning={applicantsLoading}>
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'Title', field: 'title' },
                    { title: 'Location', field: 'location' },
                    { title: 'Employment Type', field: 'employment_type' },
                    { title: 'Workplace Type', field: 'workplace_type' },
                    { title: 'Job Description', field: 'description' },
                    { title: 'Deadline', field: 'deadline' },
                    { title: 'Status', field: 'status' },                   
                  ]}
                  actions={[
                    {
                      icon: () => <FaEdit/>,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => {
                        setRowData(rowData)
                        vacancyDetailsForm.setFieldsValue({
                          ...rowData,
                        })
                        setVacanciesModal(true)
                      },
                    },
                
                    {
                      icon: () => <MdCancel />,
                      tooltip: 'Close Vacancy',
                      onClick: (event, rowData) => {
                       updateVacancyStatus(rowData)
                      },
                    },
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={vacanciesData}
                />
                </Spin>
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}

export default JobVacancies

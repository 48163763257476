import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorage } from "./localStorage";

const AdminPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'admin';

    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

const StudentPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    
    // Check if the user is authenticated
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'student' && userInfo.is_registered && userInfo.account_type === 'cta_application';
  
    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }
  

  
    return <Outlet />;
  };

  const ITCStudentPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    
    // Check if the user is authenticated
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'student' && userInfo.is_registered && userInfo.account_type === 'itc_registration';
  
    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }
  

  
    return <Outlet />;
  };

const RegPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'student' && !userInfo.is_registered && userInfo.account_type === 'cta_application';

    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

const ITCRegPrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    const isAuthenticated = userInfo && userInfo.isLoggedIn && userInfo.user_type === 'student' && !userInfo.is_registered && userInfo.account_type === 'itc_registration';

    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

export { StudentPrivateRoutes, AdminPrivateRoutes, RegPrivateRoutes, ITCRegPrivateRoutes, ITCStudentPrivateRoutes };

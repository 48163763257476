import React from 'react'
import SideNav from '../../../components/student/ITC/SideNav'
import Header from '../../../components/Header'
import BankingDetails from '../../../components/student/BankingDetails'
function ITCBankingDetails() {
  return (
  <>
        <Header/>
        <SideNav/>
        <BankingDetails/>
    </>
  )
}

export default ITCBankingDetails

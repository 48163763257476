import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  BsBookHalf,
  BsFillGrid3X3GapFill,
  BsFillPatchQuestionFill,
  BsFillPeopleFill,
  BsPersonLinesFill,
} from 'react-icons/bs';
import {IoPersonCircle} from 'react-icons/io'
import { BiLogOut, BiSolidBook, BiSolidBookOpen } from 'react-icons/bi';
import { FaBell, FaUserCog } from 'react-icons/fa';
import { TbMessageStar } from "react-icons/tb";
import { TiMessages } from "react-icons/ti";
import { GrWorkshop } from "react-icons/gr";
import { MdOutlineWork, MdPersonAdd } from 'react-icons/md';
import { getLocalStorage } from '../utils/localStorage';

function SideNav() {
  const userInfo = getLocalStorage('userInfo');

  const handleLogout = () => {
    // Clear all storage
    localStorage.clear();

    // Redirect to the login page
    window.location.href = '/';
  };
  return (
    <aside id="sidebar" className="sidebar toggle-sidebar-btn">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <NavLink exact to="/dashboard" className="nav-link">
            <BsFillGrid3X3GapFill size={20}/>
            <span style={{ padding: '5px' }}>Dashboard</span>
          </NavLink>
        </li>
        
        {userInfo.user_type === 'admin' && (
          <>
           <li class="nav-item">
              <a class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                <i class="bi bi-menu-button-wide"></i><span>Applications</span><i class="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
              <li className="nav-item">
                  <NavLink exact to="/cta_applicants" className="nav-link collapsed">
                    <BsFillPeopleFill size={20} />
                    <span style={{ padding: '5px' }}>CTA Applicants</span>
                  </NavLink>
                </li>
                  <li className="nav-item">
                    <NavLink exact to="/itc_applicants" className="nav-link collapsed">
                      <BsPersonLinesFill size={20}/>
                      <span style={{ padding: '5px' }}>IAC Applicants</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink exact to="/job_applicants" className="nav-link collapsed">
                      <GrWorkshop size={20}/>
                      <span style={{ padding: '5px' }}>Job Application</span>
                    </NavLink>
                  </li>
                
              </ul>
            </li>

            <li class="nav-item">
              <a class="nav-link collapsed" data-bs-target="#students-nav" data-bs-toggle="collapse" href="#">
                <i class="bi bi-card-list"></i><span>Students</span><i class="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="students-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
              <li className="nav-item">
                  <NavLink exact to="/itc_students" className="nav-link collapsed">
                    <BiSolidBookOpen size={20}/>
                    <span style={{ padding: '5px' }}> IAC Students</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/cta_students" className="nav-link collapsed">
                    <FaUserCog size={20}/>
                    <span style={{ padding: '5px' }}>CTA Students</span>
                  </NavLink>
                </li>
                
              </ul>
            </li>
          
            <li className="nav-item">
              <NavLink exact to="/users" className="nav-link collapsed">
              <MdPersonAdd size={20}/>

                <span style={{ padding: '5px' }}>Users</span>
              </NavLink>
            </li>
          </>
        )}
        
        <li className="nav-item">
          <NavLink exact to="/unread_enquiries" className="nav-link collapsed">
            <TiMessages size={20}/>
            <span style={{ padding: '5px' }}>View Enquries</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact to="/messages" className="nav-link collapsed">
            <TbMessageStar size={20}/>
            <span style={{ padding: '5px' }}>View Messages</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact to="/job_vacancies" className="nav-link collapsed">
            <MdOutlineWork size={20}/>
            <span style={{ padding: '5px' }}>Job Vacancies</span>
          </NavLink>
        </li>
        <li className="nav-item">
        <NavLink exact to="/adminProfile" className="nav-link collapsed" >
            <FaUserCog size={20}/>
            <span style={{padding: '5px'}}>My Profile</span>
          </NavLink>
        </li>
        
        <li className="nav-item">
          <a className="nav-link collapsed" onClick={handleLogout}>
            <BiLogOut size={20} />
            <span style={{ padding: '5px' }}>Logout</span>
          </a>
        </li>
      </ul>
    </aside>
  );
}

export default SideNav;

import React, { useEffect, useState } from 'react';
// import './style.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import { Spin } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header';
import LoginSideNav from '../LoginSidenav';
import setLocalStorage from '../../utils/localStorage';
// import Config from '../config/config';


function Registration() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [selectedCTAAttempt, setSelectedCTAAttempt] = useState('');
  const [selectedRelevantApplications, setSelectedRelevantApplication] = useState('');
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState('');
  const [loading,setLoading]= useState(false)
//   const config = Config();
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;


  const [formData, setFormData] = useState({
    next_of_kin_name: '',
    next_of_kin_email: '',
    next_of_kin_address:'',
    nok_mobile_number: '',
    next_of_kin_relationship: '',
    programme:'',
    degree_name: '',
    university_name: '',
    other_university_name: '',
    degree_class: '',
    year_attained: '',
    degree_transcript: null,
    degree_certificate: null,
    cta_attempt: '',
    latest_attempt_results: null,
    average_mark_attempted_elsewhere: '',
    relevant_applications: '',
    relevant_applications_proof: null,
    year_of_previous_cta_attempt: '',
    employment_status: '',
    employer_name: '',
    training_officer_name: '',
    training_officer_contact: '',
    cta_funding: '',
    fee_payer: '',
  });


  useEffect(() => {
    const form = document.querySelector("form"),
      nextBtn = form.querySelector(".nextBtn"),
      backBtn = form.querySelector(".backBtn"),
      allInput = form.querySelectorAll(".first input[required]");
  
    nextBtn.addEventListener("click", (event) => {
      let isFilled = true;
  
      allInput.forEach(input => {
        const errorMessage = input.nextElementSibling;
  
        if (input.value === "") {
          isFilled = false;
          input.classList.add("error");
  
          // Display an error message if it's not already visible
          if (!errorMessage) {
            const message = document.createElement("span");
            message.classList.add("error-message");
            message.textContent = "This field is required";
            input.after(message);
          }
        } else {
          input.classList.remove("error");
  
          // Remove the error message if the input is filled
          if (errorMessage) {
            errorMessage.remove();
          }
        }
      });
  
      if (isFilled) {
        form.classList.add('secActive');
      } else {
        form.classList.remove('secActive');
        event.preventDefault();  // Prevent form submission
      }
    });
  
    backBtn.addEventListener("click", () => form.classList.remove('secActive'));
  }, []);
  
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && files[0]) {
      const file = files[0];
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit

      if (file.size > maxSizeInBytes) {
        toast.error("File size exceeds the maximum limit of 5MB.");
        e.target.value = ""; 
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });

    if (name === 'university_name') {
      setSelectedUniversity(value);
    } else if (name === 'cta_attempt') {
      setSelectedCTAAttempt(value);
    } else if (name === 'relevant_applications') {
      setSelectedRelevantApplication(value);
    } else if (name === 'employment_status') {
      setSelectedEmploymentStatus(value);
    }
};


const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);

  // Check for file size limit (5MB)
  const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit
  const filesExceedLimit = Object.keys(formData).some((key) => {
      const value = formData[key];
      // Check if the value is a file and its size exceeds 5MB
      return value instanceof File && value.size > maxSizeInBytes;
  });

  if (filesExceedLimit) {
      setLoading(false);
      toast.error('One or more files exceed the 5MB size limit. Please upload smaller files.');
      return; // Prevent form submission if there are large files
  }

  const data = new FormData();
  Object.keys(formData).forEach((key) => {
      const value = formData[key];
      data.append(key, value === null ? '' : value);
  });

  try {
      const response = await axios.post(
          'https://webservice.accsoncall.com/api/student/application/cta/',
          data,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data',
              },
          }
      );

      console.log(response.data);
      setLoading(false);
      const existingUserInfo = JSON.parse(localStorage.getItem("userInfo"));
      setLocalStorage("userInfo", {
        ...existingUserInfo,
        is_registered: true,
        account_type: 'cta_application',
      });
      navigate('/studentDashboard');
      toast.success('Application submitted successfully! Please note you will be notified once your application has been approved');
  
  } catch (error) {
      setLoading(false);
      console.log('Error response:', error.response);
      const errorData = error.response?.data;
      const errorMessage = errorData?.error || "Application timeout, please refresh your page and try to submit again";

      toast.error(`Failed to submit application: ${errorMessage}`);
  }
};



  return (
    <>
    <Header/>
    <LoginSideNav/>
    <main>
      <div class="container">
    <div className="reg-container">
      <ToastContainer />
      <Spin spinning={loading}>
      <header>CTA Application</header>

      <form onSubmit={handleSubmit}>
        <div className="form first">
          <div className="details personal">
            <span className="title">Next of Kin Details</span>

            <div className="fields">
              <div className="input-field">
                <label htmlFor="next_of_kin_name">Full Name</label>
                <input id="next_of_kin_name" name="next_of_kin_name" type="text" placeholder="Enter full name" required onChange={handleInputChange} />
              </div>
              <div className="input-field">
                <label htmlFor="next_of_kin_email">Email</label>
                <input id="next_of_kin_email" name="next_of_kin_email" type="email" placeholder="Enter email address" required onChange={handleInputChange} />
              </div>
              <div className="input-field">
                <label htmlFor="nok_mobile_number">Mobile Number</label>
                <input id="nok_mobile_number" name="nok_mobile_number" type="tel" placeholder="Enter mobile number" required onChange={handleInputChange} />
              </div>
              <div className="input-field">
                <label htmlFor="next_of_kin_relationship">Relationship</label>
                <input id="next_of_kin_relationship" name="next_of_kin_relationship" type="text" placeholder="relationship with next of kin" required onChange={handleInputChange} />
              </div>
              <div className="input-field">
                <label htmlFor="next_of_kin_relationship">Address</label>
                <input id="next_of_kin_address" name="next_of_kin_address" type="text" placeholder="next of kin address" required onChange={handleInputChange} />
              </div>
              <div className="input-field">
                  <label htmlFor="programme">Select Program</label>
                  <select id="degree_class" name="programme" required onChange={handleInputChange}>
                    <option value="" disabled selected>Select Program You're Applying For</option>
                    <option value="CTA Phase 2 (One Year CTA)">CTA Phase 2 (One Year CTA)</option>
                    <option value="CTA Phase 1 (Two Year CTA)">CTA Phase 1 (Two Year CTA)</option>
                    <option value="Refresher Course">Refresher Course</option>
                  </select>
                </div>
            </div>

            <div className="details address">
              <span className="title">Degree Qualifications</span>
              <div className="fields">
                <div className="input-field">
                  <label htmlFor="degree_name">Degree Name</label>
                  <input id="degree_name" name="degree_name" type="text" placeholder="Enter Degree Name" required onChange={handleInputChange} />
                </div>
                <div class="input-field">
                  <label>University Name</label>
                  <select name="university_name" onChange={handleInputChange} required>
                    <option disabled selected>Select Option</option>
                    <option value="Africa University">Africa University (AU)</option>
                    <option value="Arrupe Jesuit University">Arrupe Jesuit University (AJU)</option>
                    <option value="Bindura University of Science Education">Bindura University of Science Education</option>
                    <option value="Catholic University of Zimbabwe">Catholic University of Zimbabwe (CUZ)</option>
                    <option value="Chinhoyi University of Technology">Chinhoyi University of Technology (CUT)</option>
                    <option value="Great Zimbabwe University">Great Zimbabwe University (GZU)</option>
                    <option value="Harare Institute of Technology">Harare Institute of Technology (HIT)</option>
                    <option value="IIE MSA">IIE MSA (previously Monash South Africa)</option>
                    <option value="Lupane State University">Lupane State University (LSU)</option>
                    <option value="Midlands State University">Midlands State University (MSU)</option>
                    <option value="National University of Science and Technology">National University of Science and Technology (NUST)</option>
                    <option value="Nelson Mandela Metropolitan University">Nelson Mandela Metropolitan University (NMMU)</option>
                    <option value="Rhodes University">Rhodes University (RU)</option>
                    <option value="Solusi University">Solusi University (Solusi)</option>
                    <option value="University of Cape Town">University of Cape Town (UCT)</option>
                    <option value="University of Johannesburg">University of Johannesburg (UJ)</option>
                    <option value="University of South Africa">University of South Africa (UNISA)</option>
                    <option value="University of Witwatersrand">University of Witwatersrand (Wits)</option>
                    <option value="University of Zimbabwe">University of Zimbabwe (UZ)</option>
                    <option value="Women’s University in Africa">Women’s University in Africa (WUA)</option>
                    <option value="Zimbabwe Ezekiel Guti University">Zimbabwe Ezekiel Guti University (ZEGU)</option>
                    <option value="Zimbabwe Open University">Zimbabwe Open University (ZOU)</option>
                    <option value="Other">Other (please specify)</option>
                </select>

              </div>
              {selectedUniversity === 'Other' && (
                  <div className="input-field">
                    <label htmlFor="other_university">Other University Name</label>
                    <input id="other_university" name="other_university_name" type="text" placeholder="Enter other university name" onChange={handleInputChange} />
                  </div>
                )}
                <div className="input-field">
                  <label htmlFor="degree_class">Degree Class</label>
                  <select id="degree_class" name="degree_class" required onChange={handleInputChange}>
                    <option value="" disabled selected>Select Degree Class</option>
                    <option value="1">1</option>
                    <option value="2.1">2.1</option>
                    <option value="2.2">2.2</option>
                    <option value="3">3</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="input-field">
                  <label htmlFor="year_attained">Year Degree attained</label>
                  <input id="year_attained" type="date" name="year_attained" required onChange={handleInputChange} />
                </div>
                <div className="input-field">
                  <label htmlFor="degree_transcript">Degree Transcript</label>
                  <input id="degree_transcript" name="degree_transcript" type="file" required onChange={handleInputChange} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
                </div>
                <div className="input-field">
                  <label htmlFor="degree_certificate">Degree Certificate</label>
                  <input id="degree_certificate" name="degree_certificate" type="file" required onChange={handleInputChange} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
                </div>
              </div>
              <div className="buttons">
                <button type="button" className="nextBtn">
                  <span className="btnText">Next</span>
                  <i className="uil uil-navigator"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="form second">
          <div className="details address">
            <span className="title">Other Qualifications</span>
            <div className="fields">
              <div className="input-field">
                <label htmlFor="cta_attempt">Have You Attempted CTA or Bridging Before</label>
                <select id="cta_attempt" name="cta_attempt" required onChange={handleInputChange}>
                  <option value="" disabled selected>Select option</option>
                  <option value="No">No</option>
                  <option value="CTA 1">CTA 1</option>
                  <option value="CTA 2">CTA 2</option>
                  <option value="Bridging">Bridging</option>
                </select>
              </div>
              {/* {selectedCTAAttempt != 'No' && (<> */}
              <div className="input-field">
                <label htmlFor="latest_attempt_results">Upload Latest Results</label>
                <input id="latest_attempt_results" name="latest_attempt_results" type="file" onChange={handleInputChange} disabled={selectedCTAAttempt === 'No'} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
              </div>
              
              <div className="input-field">
                <label htmlFor="average_mark_attempted_elsewhere">Average Mark for BCTA or CTA</label>
                <input id="average_mark_attempted_elsewhere" name="average_mark_attempted_elsewhere" type="number" placeholder="Average mark"  onChange={handleInputChange} disabled={selectedCTAAttempt === 'No'} />
              </div>
              <div className="input-field">
                <label htmlFor="year_of_previous_cta_attempt">Year Attempted CTA or Bridging</label>
                <input id="year_of_previous_cta_attempt" type="date" name="year_of_previous_cta_attempt"  onChange={handleInputChange} disabled={selectedCTAAttempt === 'No'} />
              </div>
              {/* </> */}
              {/* )} */}
              <div className="input-field">
                <label htmlFor="relevant_applications">Other relevant qualifications</label>
                <select id="relevant_applications" name="relevant_applications" required onChange={handleInputChange}>
                  <option value="" disabled selected>Select Option</option>
                  <option value="None">None</option>
                  <option value="ACCA">ACCA</option>
                  <option value="CIMA">CIMA</option>
                  <option value="CPA">CPA</option>
                  <option value="Others">Other</option>
                </select>
              </div>
              <div className="input-field">
                <label htmlFor="relevant_applications_proof">Proof of Other Qualifications</label>
                <input id="relevant_applications_proof" name="relevant_applications_proof" type="file"  onChange={handleInputChange}  disabled={selectedRelevantApplications==='None'} accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"/>
              </div>
              
            </div>
          </div>
          <div className="details address">
            <span className="title">Employment Details</span>

            <div className="fields">
              <div class="input-field">
                  <label>Are you currently employed</label>
                  <select name="employment_status" onChange={handleInputChange} required>
                      <option disabled selected>Select option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                  </select>
              </div>
            
              <div className="input-field">
                <label>Employer Name</label>
                <input name="employer_name" type="text" onChange={handleInputChange} placeholder="Enter employer name" disabled={selectedEmploymentStatus==='no'} />
              </div>
              <div className="input-field">
                <label>Training Officer Contact Name</label>
                <input name="training_officer_name" type="text" onChange={handleInputChange} placeholder="employer / training officer contact name"  disabled={selectedEmploymentStatus==='no'} />
              </div>

              <div className="input-field">
                <label>Training Officer Phone Number</label>
                <input name="training_officer_contact" type="text"onChange={handleInputChange}  placeholder="employer / training officer phone"  disabled={selectedEmploymentStatus==='no'}  />
              </div>
           
              <div class="input-field">
                  <label>Who Will Be Funding Your CTA</label>
                  <select name="cta_funding" onChange={handleInputChange} required>
                      <option disabled selected>Select Option</option>
                      <option value="Self Funded">Self Funded</option>
                      <option value="Employer">Employer</option>
                      <option value="Bursary">Bursary</option>
                      <option value="Other">Other</option>
                  </select>
              </div>
              <div className="input-field">
                <label>Fee Payer Name</label>
                <input type="text"name='fee_payer' onChange={handleInputChange} placeholder="Fee Payer Name" required />
              </div>
              
            </div>

            <div className="buttons">
              <div className="backBtn">
                <i className="uil uil-navigator"></i>
                <span className="btnText">Back</span>
              </div>

              <button type="submit" className="submit">
                <span className="btnText">Submit Application</span>
                <i className="uil uil-navigator"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
      </Spin>
      
    </div>
    </div>
    </main>
    </>
  );
}

export default Registration;
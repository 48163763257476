import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Config from '../../config/config'
import { Spin } from 'antd'
import { getLocalStorage } from '../../utils/localStorage'

function Dashboard() {
  const [dashboardStats, setDashboardStats]=useState([])
  const [recentEnquiries, setRecentEnquiries]=useState([])
  const [enquriesLoading, setEnquiriesLoading]=useState(false)
  const config = Config()
  const superuser = localStorage.getItem('superuser');
  const userInfo = getLocalStorage('userInfo');
  useEffect(() => {
    getStudentsStatistics()
    getUnreadEnquiries()
  }, [])

  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const getStudentsStatistics = () => {
    const url = ` ${config.base_url}students/statistics/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setDashboardStats(res.data);
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };
  const getUnreadEnquiries = () => {
    setEnquiriesLoading(true)
    const url = ` ${config.base_url}enquiries/view_pending_enquiries/`;
    axios
      .post(url, headers)
      .then((res) => {
        setEnquiriesLoading(false)
        if (res.status === 200) {
          setRecentEnquiries(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setRecentEnquiries(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const getStatusClass = status => {
    if(status === 'pending') return 'warning';
    if(status === 'read') return 'success';
    // etc
  }

  return (
  <>

  <div class="pagetitle">
    <h1>Dashboard</h1>
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active">Dashboard</li>
      </ol>
    </nav>
  </div>
  <div className="row">
  {userInfo.user_type === 'admin' && (
          <>
    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                CTA Applicants
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.cta_applicants || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Pending Applications</span>
              <a
                href="/cta_applicants"
               
                className="btn btn-sm w-100 green-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>
    
    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                ITC Applicants
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.itc_applicants || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Pending Applications</span>
              <a
                href="/itc_applicants"
               
                className="btn btn-sm w-100 green-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>
    </>)}
    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                CTA Students
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.cta_students || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Approved Students</span>
              <a
                href="cta_students"
               
                className="btn btn-sm w-100 blue-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>
    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                ITC Students
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.itc_students || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Approved Students</span>
              <a
                href="/itc_students"
               
                className="btn btn-sm w-100 blue-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>
    {userInfo.user_type === 'admin' && (
      <>
    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                Job Vacancies
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.open_vacancies || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Open Vacancies</span>
              <a
                href="/job_vacancies"
               
                className="btn btn-sm w-100 blue-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>

    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                Job Applications
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.job_applicants || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Pending Applications</span>
              <a
                href="/job_applicants"
               
                className="btn btn-sm w-100 blue-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>
    </>)}
    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                Website Messages
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.unread_messages || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Unread Messages</span>
              <a
                href="/messages"
               
                className="btn btn-sm w-100 green-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>
    <div className="col-12 col-md-6 col-lg-3 mt-4">
        <div className="card box-shadow-1 border-1 rounded-0">
        <Spin spinning={enquriesLoading}>
          <div className="card-body mt-3 mt-3 ">
            <h6 className="text-uppercase fw-800 fs-14px text-green-3">
                Website Enquiries
              </h6>

              <h6 className="text-green fw-800 d-inline">
              {dashboardStats.unread_enquiries || 0}
              </h6>
              <span className="text-green ms-2 fw-600">Unread Enquiries</span>
              <a
                href="/unread_enquiries"
               
                className="btn btn-sm w-100 green-gradient-bg-1 border-0  text-white fw-800 mt-2 fs-12px"
              >
                View
              </a>
          </div>
          </Spin>
        </div>
    </div>
   
  </div>
  </>
  )
}

export default Dashboard

import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin, message} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaCheck, FaDownload, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import {MdCancel, MdDelete} from 'react-icons/md';
import { CSVLink } from 'react-csv'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TiMessages } from 'react-icons/ti'
import { IoCheckmarkDoneSharp } from "react-icons/io5";

function UnreadEnquiries() {
  const [unreadEnquiries, setUnreadEnquiries]= useState([])
  const [enquriesLoading, setEnquiriesLoading] = useState(false)
  const [unreadEnquiriesDetailsModal, setUnreadEnquiriesDetailsModal] = useState(false)
  const [rowData, setRowData] = useState({})
  const [unreadEnquiriesDetailsForm] = Form.useForm()
  
  const config = Config()
  useEffect(() => {
    getUnreadEnquiries()
  }, [])

  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const triggerToast = () => {
    toast("Enquity Deleted");
  }
  const triggerResponseToast = (message) => {
    toast("Enquiry Response Sent");
  }
  const triggerError = () => {
    toast("Failed to approve applicant");
  }
  const triggerDeleteError = () => {
    toast("Failed to delete enquiry");
  }
  
  const getUnreadEnquiries = () => {
    setEnquiriesLoading(true)
    const url = ` ${config.base_url}enquiries/view_pending_enquiries/`;
    axios
      .post(url, headers)
      .then((res) => {
        setEnquiriesLoading(false)
        if (res.status === 200) {
          setUnreadEnquiries(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setEnquiriesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const getFilteredEnquiries = (values) => {
    setEnquiriesLoading(true)
    const url = ` ${config.base_url}enquiries/view_pending_enquiries/`;
    let payload = {
      start_date: values.start_date,
      end_date: values.end_date,
      status: values.status,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        setEnquiriesLoading(false)
        if (res.status === 200) {
          setUnreadEnquiries(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setEnquiriesLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const replyEnquiry = (values) => {
    setEnquiriesLoading(true)
    const url = ` ${config.base_url}enquiries/view_read_enquiries/`;
    const payload = {
      enquiryID: values.id,
      response: values.response,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        setEnquiriesLoading(false)
        if (res.status === 200) {
          triggerResponseToast()
          window.location.href = '/unread_enquiries';
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setEnquiriesLoading(false)
        triggerError()
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };

  const deleteEnquiry = (rowData) => {
    
    setEnquiriesLoading(true)
    const url = ` ${config.base_url}enquiries/view_read_enquiries/${rowData.id}`;
    axios
      .delete(url, headers)
      .then((res) => {
        setEnquiriesLoading(false)
        if (res.status === 204) {
          triggerToast()
          window.location.href = '/unread_enquiries';
        } else if (res.status === 401) {
          triggerDeleteError()
        }
      })
      .catch((error) => {
        setEnquiriesLoading(false)
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };
  
  const updateEnquiry = (rowData) => {
    setEnquiriesLoading(true)
    const url = ` ${config.base_url}enquiries/view_read_enquiries/`;
    const payload = {
      enquiryID: rowData.id,
    }
    axios
      .patch(url, payload, headers)
      .then((res) => {
        setEnquiriesLoading(false)
        if (res.status === 200) {
          triggerToast()
          window.location.href = '/unread_enquiries';
        } else if (res.status === 401) {
          triggerDeleteError()
        }
      })
      .catch((error) => {
        setEnquiriesLoading(false)
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };
  
  const unreadEnquiriesData = (unreadEnquiries.Enquiries ?? []).map((item) => {
    return {
      ...item,
    };
  });
  const unreadEnquiriesHeaders = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Address ', key: 'address'},
    { label: 'Registration Number ', key: 'reg_number'},
    { label: 'Whatsapp Number ', key: 'whatsapp_number'},
  
  ]

  return (
    <>
      <ToastContainer />
      <Modal
        style={{ marginTop: '-20px' }}
        visible={unreadEnquiriesDetailsModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setUnreadEnquiriesDetailsModal(false)
        }}
        width={800}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Reply Message</h5>
                 
                  <Spin spinning={enquriesLoading}>
                    <Form
                      form={unreadEnquiriesDetailsForm}
                      onFinish={(values) => replyEnquiry(values)}
                      name="editOrderForm"
                      autoComplete="off"
                    >
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Enquiry</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'message'
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">User Email</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'email'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Reply Message</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'response'
                          >
                        <textarea class="form-control" id="inputText" rows="5" />
                      </Form.Item>
                      </div>
                    </div>
                    
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Send Message</button>
                    </div>
                  </Form>
                  </Spin>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal> 
    <div className="pagetitle">
      <h1> Equiries</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Enquires</li>
          <li className="breadcrumb-item active">Enquiries</li>
        </ol>
       
      </nav>
    </div>
    <section className="section">
      
      <div className="">
        <div className="card border-0 p-0 rounded-0">
          <Form
              // form={form}
              name="basic"
              onFinish={(values) => getFilteredEnquiries(values)}
              initialValues={{ mode: 'all' }}
            >
              <div className="row px-4 m-0">
                <div className="col-12 col-md-4 mt-4">
                  <label className="text-green-2 fw-700 px-0">Status</label>
                  <Form.Item name="status">
                    <select className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0">
                      <option value="">Select Status</option>
                      <option value="pending"> Pending Equiries</option>
                      <option value="read"> Read Equiries</option>
                    </select>
                  </Form.Item>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mt-4">
                  <label className="text-green-2 fw-700 px-0">Start Date</label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please select start day!',
                      },
                    ]}
                    name="start_date"
                  >
                    <input
                      className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                      type="date"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mt-4">
                  <label className="text-green-2 fw-700 px-0">End Date</label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please select end day!',
                      },
                    ]}
                    name="end_date"
                  >
                    <input
                      className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                      type="date"
                    />
                  </Form.Item>
                </div>
                <div className="col-2 col-md-2 col-lg-2 mt-4" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <input
                    className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                    type="submit"
                    value="Filter"
                  />    
                </div>
              </div><br/>
            </Form>
          </div>
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    Enquiries
                    </h4>
                  </>
              </div>
              
              <div className="card-body px-0 pt-2 pb-4">
                <div className="table-responsive">
                <Spin spinning={enquriesLoading}>
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'From', field: 'name' },
                    { title: 'Message', field: 'message' },
                    { title: 'Status', field: 'status' },
                    { title: 'Date', field: 'date_created' },

                    
                  ]}
                  actions={[
                    {
                      icon: () => <TiMessages />,
                      tooltip: 'Reply',
                      onClick: (event, rowData) => {
                        setRowData(rowData)
                        unreadEnquiriesDetailsForm.setFieldsValue({
                          ...rowData,
                        })
                        setUnreadEnquiriesDetailsModal(true)
                      },
                    },
                    {
                      icon: () => <IoCheckmarkDoneSharp />,
                      tooltip: 'Mark as read',
                      onClick: (event, rowData) => {
                       updateEnquiry(rowData)
                      },
                    },
                    {
                      icon: () => <MdDelete />,
                      tooltip: 'Delete Enquiry',
                      onClick: (event, rowData) => {
                       deleteEnquiry(rowData)
                      },
                    },
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={unreadEnquiriesData}
                />
                </Spin>
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}

export default UnreadEnquiries

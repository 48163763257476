import React from 'react'
import SideNav from '../../../components/student/ITC/SideNav'
import StudentDashboard from '../../../components/student/ITCDashboard'
import Header from '../../../components/Header'
function ITCStudentDashboard() {
  return (
  <>
        <Header/>
        <SideNav/>
        <StudentDashboard/>
    </>
  )
}

export default ITCStudentDashboard

import React from 'react'
import SideNav from '../../components/student/Sidenav'
import Header from '../../components/Header'
import BankingDetails from '../../components/student/BankingDetails'
function CTABankingDetails() {
  return (
  <>
        <Header/>
        <SideNav/>
        <BankingDetails/>
    </>
  )
}

export default CTABankingDetails
